<template>
  <div class="my-10 flex h-full w-full flex-col items-center">
    <EoCard label="Verdwaald?">
      <EoCardTitle class="text-center">Verdwaald?</EoCardTitle>
      <EoCardText class="my-2 text-center"
        >Ga terug naar de homepage</EoCardText
      >
      <EoButton
        color="purple-700"
        to="https://www.eo.nl/"
        class="mt-4 text-white"
        rounded
        label="Naar de homepage"
        style="text-decoration: none"
        >Naar EO.nl</EoButton
      >
    </EoCard>
  </div>
</template>
